import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../i18n'
// Containers
const TheContainer = () => import('@/containers/TheContainer')
const Login = () => import('@/views/Login')

// ViewsCreateServiceOrder
const Dashboard = () => import('@/views/Dashboard')
const Settings = () => import('@/views/Settings')
const Users = () => import('@/views/Users')
const Versions = () => import('@/views/Versions')
const Analytics = () => import('@/views/Analytics')
const UnfinishedDeliveries = () => import('@/views/UnfinishedDeliveries')
const Surveys = () => import('@/views/Surveys')
const ShipTos = () => import('@/views/ShipTos')
const SafetyChecks = () => import('@/views/SafetyCheck')
const CompletedAtWork = () => import('@/views/CompletedAtWork')
const Answers = () => import('@/views/Answers')
const CarrierConvocations = () => import('@/views/CarrierConvocations')
const Vehicles = () => import('@/views/Vehicles.vue')


Vue.use(Router)

function configRoutes() {
  return [
    { meta: { type: 'public' }, path: '/login', name: 'login', component: Login },
    {
      meta: { type: 'public' }, path: '/', redirect: '/dashboard', name: i18n.i18next.t('menu_home'), component: TheContainer,

      children: [
        { meta: { type: 'public' }, path: 'dashboard', name: i18n.i18next.t('drivers'), component: Dashboard },
        { meta: { type: 'public' }, path: 'settings', name: i18n.i18next.t('country_settings'), component: Settings },
        // { meta: { type: 'public' }, path: 'users', name: 'Console Users', component: Users },
        { meta: { type: 'public' }, path: 'versions', name:  i18n.i18next.t('version_control'), component: Versions },
        // { meta: { type: 'public' }, path: 'analytics', name: 'Analytics', component: Analytics },
        { meta: { type: 'public' }, path: 'unfinisheddeliveries', name: i18n.i18next.t('unfinished_load_lines'), component: UnfinishedDeliveries },
        { meta: { type: 'public' }, path: 'surveys', name: i18n.i18next.t('surveys'), component: Surveys },
        { meta: { type: 'public' }, path: 'shipTos', name: i18n.i18next.t('shiptos'), component: ShipTos },
        { meta: { type: 'public' }, path: 'safetyChecks', name: i18n.i18next.t('safety_check'), component: SafetyChecks },
        { meta: { type: 'public' }, path: 'completedAtWork', name: i18n.i18next.t('completed'), component: CompletedAtWork },
        { meta: { type: 'public' }, path: 'vehicles', name: i18n.i18next.t('carrier_vehicles'), component: Vehicles },
        {
          meta: { type: 'public' }, path: 'surveys/:id', name:i18n.i18next.t('surveys'), component: Answers, children: [
            { path: 'answers', name: i18n.i18next.t('answers') }
          ]
        },
        { meta: { type: 'public'}, path: 'convocations', name:  i18n.i18next.t('carrier_convocation'), component: CarrierConvocations },
      ]

    }
  ]
}




let router = new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  saveScrollPosition: true,
  history: true,
  force: true,
  routes: configRoutes()
})



router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("user");

  var isAuthenticated = false;
  try {
    isAuthenticated = (user !== undefined && user !== null);

  } catch (e) { console.log(e) }

  if (to.path != '/ConfigFile' && to.path != '/login') {
    if (Vue.prototype.ConfigFile) {
      console.log("configFile");
      next('/ConfigFile')
    } else if (!isAuthenticated) {

      next('/login')


    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
