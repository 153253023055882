import Vue from "vue";
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';

// Instalar el plugin VueI18Next
Vue.use(VueI18Next);

// Definir las traducciones iniciales en inglés
const defaultResources = {
  en: {
    translation: {
      loading: 'Loading...',
      login: 'Login',
      password: 'Password',
      userName: 'Username',
      userNamePasswordIncorrect: 'Username or password are incorrect',
    }
  }
};

// Inicializar i18next
let i18n;
if (!localStorage.getItem("i18n") || localStorage.getItem("i18n") === "") {
  i18next.init({
    lng: 'en',
    fallbackLng: 'en',
    resources: defaultResources
  });
  i18n = new VueI18Next(i18next);
  localStorage.setItem("i18n", JSON.stringify({ language: 'en', translations: { en: defaultResources.en.translation } }));
} else {
  const idiomasI18n = JSON.parse(localStorage.getItem("i18n"));
  i18next.init({
    lng: idiomasI18n.language || 'en',
    fallbackLng: 'en',
    returnEmptyString: false,
    returnNull: false,
    parseMissingKeyHandler: (key) => `[__${key}__]`,
    resources: defaultResources // Recursos base
  });
  i18n = new VueI18Next(i18next);
  
  // Cargar traducciones adicionales desde localStorage
  if (idiomasI18n.translations && Object.keys(idiomasI18n.translations).length > 0) {
    for (const [lang, translations] of Object.entries(idiomasI18n.translations)) {
      i18n.i18next.addResourceBundle(lang, "translation", translations);
    }
  }
  i18n.i18next.changeLanguage(idiomasI18n.language || 'en');
}

export default i18n;